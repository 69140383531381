import { BsShareFill } from "react-icons/bs";
import Share from "./Share";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "..";

const ShareMyPage = () => {
  const analytics = getAnalytics(firebaseApp);
  const shareData = {
    url: window.location.href,
    title: document.title, 
    text: 'Here\'s Eric\'s name card!'
  }
  const onInteraction = () => { logEvent(analytics, 'share_button_clicked'); }
  const onSuccess = () => { logEvent(analytics, 'shared_successfully'); }
  const onError = () => { logEvent(analytics, 'shared_ failed'); }
  return (
    <Share
      children={
      <button className='w-60 rounded bg-primary shadow-sm shadow-slate-500 flex flex-row justify-center text-white text-xl p-3 space-x-3 font-serif'>
        <BsShareFill className='self-center'/>
        <div className="text-base">
          {'Share to My Network'}
        </div>
      </button>}
      shareData={shareData}
      onSuccess={onSuccess}
      onInteraction={onInteraction}
      onError={onError}
    />
  );
}
 
export default ShareMyPage;