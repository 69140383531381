import './Suspended.css';
import { useState } from 'react';
import { BsTelephoneFill, BsEnvelopeFill, BsLinkedin, BsWhatsapp, BsSignal, BsInstagram, BsPersonFillAdd, BsShareFill } from 'react-icons/bs';
import ShareMyPage from '../components/ShareMyPage';
import 'animate.css';
import {QRCodeSVG} from 'qrcode.react';

const Suspended = () => {
  const name = "Site Under Maintenance.";
  const title = "Coming back soon!";

  const [showModal, setShowModal] = useState(false);
  const [isFadeOut, setIsFadeOut] = useState(false);

  const timeout = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }

  const handleCloseModal = async () => {
    setIsFadeOut(true);
    await timeout(700);
    setShowModal(false);
    setIsFadeOut(false);
  }

  return (
    <div className='p-6 bg-background h-screen font-serif'>
      <div className="px-6 mt-28 max-w-sm mx-auto bg-white rounded-xl shadow-xl h-56 animate__animated animate__backInDown">
        <div className='flex flex-col justify-center items-center relative top-20'>
          <>
            <div className="text-2xl font-medium text-primary font-serif animate__animated animate__fadeInDown animate__delay-1s">{name}</div>
            <div className="text-sm text-slate-500 font-serif mb-6 animate__animated animate__fadeInTopLeft animate__delay-1s">{title}</div>
          </>
        </div>
      </div>
      {showModal && 
      <div className={!isFadeOut ? 'animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut'}>
        <div
          className={!isFadeOut ? "fixed inset-0 px-10 bg-gray-800 bg-opacity-50 overflow-auto h-full w-full z-10 " : "fixed inset-0 px-10 bg-gray-600 bg-opacity-50 overflow-auto h-full w-full z-10 animate__animated animate__fadeOut"}
          id="my-modal"
          onClick={() => handleCloseModal()}
        >
          
        </div>
        <div className='z-20 max-w-sm mx-auto bg-white rounded-xl shadow-xl pt-10 pb-10 overflow-visible flex flex-col items-center justify-center relative bottom-96 h-3/4'>
          <QRCodeSVG
            value={window.location.href}
            size={240}
            fgColor="#004d61" 
            level='H'
            className='mb-5'
          />
          <ShareMyPage/>
        </div>
      </div>}
      {/* <div className='text-xs mx-auto absolute bottom-0'>
        {"Copyright © 2023 Eric Mak. All Rights Reserved."}
      </div> */}
    </div>
  );
}

export default Suspended;
