import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { firebaseApp } from "..";

export const getStorageUrl = async (path) => {
  // Create a reference to the file we want to download
  const storage = getStorage(firebaseApp, 'gs://ericmaksite.appspot.com');
  const starsRef = ref(storage, path);

  try {
    const url = await getDownloadURL(starsRef);
    return url;
  } catch (error) {

    console.log(error);
    return;
    // switch (error.code) {
    //   case 'storage/object-not-found':
    //     // File doesn't exist
    //     console.log()
    //     break;
    //   case 'storage/unauthorized':
    //     // User doesn't have permission to access the object
    //     break;
    //   case 'storage/canceled':
    //     // User canceled the upload
    //     break;

    //   // ...

    //   case 'storage/unknown':
    //     // Unknown error occurred, inspect the server response
    //     break;
    // }
  }

}