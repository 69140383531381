import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Suspended from './pages/Suspended';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyAPs_5zc1IUtyy6N5EzE6K4WmzJvdjpb2U",
  authDomain: "ericmaksite.firebaseapp.com",
  projectId: "ericmaksite",
  storageBucket: "ericmaksite.appspot.com",
  messagingSenderId: "324924374018",
  appId: "1:324924374018:web:64245cf99f2d6a974dbb1b",
  measurementId: "G-39R9Z9YK1Y"
};

export const firebaseApp = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspended />
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
